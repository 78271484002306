<template>
  <div class="contents register wedding funding banner">
    <div class="title flexB">
      <h2>팝업 관리</h2>
    </div>
    <div class="box one">
      <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="홈 이벤트" name="popup">
          <h2 class="bold">홈 이벤트 팝업 미리보기</h2>
        </el-tab-pane>
        <el-tab-pane label="앱 팝업" name="appView"
          ><h2 class="bold">앱 팝업 미리보기</h2>
        </el-tab-pane>
      </el-tabs>
      <div
        class="box one"
        v-if="images.filter((item) => item.type == activeName).length > 0"
      >
        <h2 class="bold">팝업 이미지</h2>
        <div class="flexL">
          <div>
            <div class="flex imgBox" v-if="images.length > 0">
              <div
                v-for="(item, i) in images.filter(
                  (item) => item.type == activeName
                )"
                :key="i"
                class="imgWrap popup"
              >
                <img :src="item.image" alt="이미지" id="img" />
                <a class="cancel" @click="handleDelete(item._id, item.image)">
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">팝업 이미지 업로드</h2>
      <div class="flexL" v-if="activeName == 'popup'">
        <p class="bold">URL</p>
        <input type="text" v-model="url" />
      </div>
      <div class="flexL">
        <p class="bold">이미지</p>
        <div class="flexB">
          <div class="filebox">
            <label for="imgUpload">찾아보기</label>
            <input
              type="file"
              ref="images"
              id="imgUpload"
              @change="submit()"
              accept="image/*"
              multiple
            />
            <span>360*360 사이즈를 권장합니다.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFile, deleteFile } from "@/api/file";
import { createBanner, fetchBanner, deleteBanner } from "@/api/admin";
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  // components: {
  //   Swiper,
  //   SwiperSlide,
  // },
  data() {
    return {
      activeName: "popup",
      url: "",
      images: [],
      banners: [],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: { el: ".swiper-pagination", clickable: true },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "15");
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    handleClick(tab, popup) {
      console.log(tab, popup);
    },
    submit() {
      if (this.url == "" && this.activeName == "popup") {
        this.$confirm("URL 없이 등록하시겠습니까?", {
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          center: true,
        })
          .then(() => {
            this.sendFile();
          })
          .catch(() => {
            return;
          });
      } else {
        this.sendFile();
      }
    },
    sendFile() {
      this.file = this.$refs.images.files[0];
      if (this.file == undefined || this.file == "") {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);
      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          this.file = "";
          let data = {
            type: this.activeName,
            image: res.data.url,
            url: this.url,
          };
          await createBanner(data);
          this.getBanner();
          this.url = "";
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleDelete(id, url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        deleteFile(url).then(async (res) => {
          if (res.status == 200) {
            let data = {
              url: url,
              bannerId: id,
            };
            await deleteBanner(data);
            this.getBanner();
          } else {
            this.$alert("서버오류 입니다. 다시 파일을 업로드 해주세요.", {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
    getBanner() {
      fetchBanner().then((res) => {
        if (res.data.status == 200) {
          this.images = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
